body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background-image: url(/static/media/image.699dd1c0.jpg);
  border-style: double;
  border-color: blue;
  border-radius: 12px;
  min-height: 1000px;
}

.App-header {
  font-size: xx-large;
  color: gold;
  padding: 10px;
  text-align: right;
}
.sub-header {
  color: rgb(0, 255, 136);
  margin: 0 60px;
  padding: 10px;
  text-align: right;
}
.icon {
  max-width: 100px;
  max-height: 100px;
}
.logo {
  text-align: right;
  max-width: 150px;
  max-height: 350px;
}
.card-text {
  color: aqua;
}

.card-title {
  color: aliceblue;
  font-weight: 400;
  font-size: 30px;
}
.meaningshow {
  background: linear-gradient(
    90deg,
    #f7babc,
    #f5dbb7 10%,
    #d5d7a1 20%,
    #aed29e 30%,
    #9ccbcf 40%,
    #a3cfd9 50%,
    #96adc9 60%,
    #9e9dce 70%,
    #b798c7 80%,
    #ce9abf 90%,
    #e5adab
  );
}
.meaningshow > div {
  max-height: 700px;
}

